import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import useCommunityPolicies from '@aurora/shared-client/components/community/useCommunityPolicies';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { canAccessPageBuilder as checkCanAccessPageBuilder } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import Icons from '@aurora/shared-client/icons';
import type { NodePageAndParams } from '@aurora/shared-client/routes/adminRoutes';
import { AdminPages } from '@aurora/shared-client/routes/adminRoutes';
import type { ManageContentPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useAdminUserRoutes from '@aurora/shared-client/routes/useAdminRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  BlogPoliciesFragment,
  BoardPoliciesFragment,
  ForumPoliciesFragment,
  IdeaPoliciesFragment,
  OccasionPoliciesFragment,
  TkbPoliciesFragment
} from '@aurora/shared-generated/types/graphql-types';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { BoardAction } from '../../../../types/enums';
import useEndUserPageDescriptor from '../../../useEndUserPageDescriptor';
import { usePageEditorLink } from '../../../usePageEditorLink';
import useTranslation from '../../../useTranslation';
import localStyles from './BoardActionMenu.module.pcss';

const log = getLog(module);

interface BoardActionMenuItem {
  /**
   * Name of the action
   */
  name: BoardAction;
  /**
   * Method to determine whether to display the action item
   * @return whether or not to display the action item.
   */
  policy?: () => boolean;
  /**
   * Event handler for the action taken
   */
  actionHandler: () => void;
}

interface Props {
  /**
   * the Board information object
   */
  board: BoardPoliciesFragment & Pick<Board, 'id' | 'conversationStyle'>;
}

/**
 * Display Board Action Menu
 *
 * Is not displayed when user does not meet policy criteria to see the any of the menu items
 *
 * @author Guido Mininno
 */
const BoardActionMenu: React.FC<React.PropsWithChildren<Props>> = ({ board }) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.BOARD_ACTION_MENU
  );
  const adminRouter = useAdminUserRoutes().router;
  const { router: endUserRouter } = useEndUserRoutes();
  const { isEditable: isPageEditable } = useEndUserPageDescriptor();
  const { link: pageEditorLink } = usePageEditorLink();

  const {
    data: communityPoliciesData,
    loading: communityPoliciesLoading,
    error: communityPoliciesError
  } = useCommunityPolicies(module, {
    useCanAccessPageBuilder: true
  });

  if (textLoading || communityPoliciesLoading) {
    return null;
  }

  if (communityPoliciesError) {
    log.error(`Error fetching policy information: ${communityPoliciesError.message}`);
  }

  const canAccessPageBuilder: boolean = checkCanAccessPageBuilder(communityPoliciesData?.coreNode);

  /**
   * Event handler for Edit Board Settings action
   */
  async function onEditBoardSettingsHandler(): Promise<void> {
    await adminRouter.pushRoute<NodePageAndParams>(AdminPages.NodeSettingsPage, {
      nodeId: IdConverter.decodeId(tenant, board.id)
    });
  }

  /**
   * Event handler for Manage Content in Board action
   */
  async function onManageBoardContentHandler(): Promise<void> {
    await endUserRouter.pushRoute<ManageContentPageAndParams>(
      EndUserPages.ManageContentPage,
      null,
      {
        [EndUserQueryParams.SEARCH_FILTER_BY_LOCATION]: IdConverter.decodeId(tenant, board?.id)
      }
    );
  }

  /**
   * Type predicate to check and type cast a board policies fragment as a blog policies fragment
   *
   * @param policyFragment The board policies fragment being checked and type cast
   * @returns boolean whether the fragment is a blog policies fragment
   */
  function isBlogPolicies(
    policyFragment: BoardPoliciesFragment
  ): policyFragment is BlogPoliciesFragment {
    return (policyFragment as BlogPoliciesFragment).blogPolicies !== undefined;
  }

  /**
   * Type predicate to check and type cast a board policies fragment as a tkb policies fragment
   *
   * @param policyFragment The board policies fragment being checked and type cast
   * @returns boolean whether the fragment is a tkb policies fragment
   */
  function isTkbPolicies(
    policyFragment: BoardPoliciesFragment
  ): policyFragment is TkbPoliciesFragment {
    return (policyFragment as TkbPoliciesFragment).tkbPolicies !== undefined;
  }

  /**
   * Type predicate to check and type cast a board policies fragment as a idea policies fragment
   *
   * @param policyFragment The board policies fragment being checked and type cast
   * @returns boolean whether the fragment is a idea policies fragment
   */
  function isIdeaPolicies(
    policyFragment: BoardPoliciesFragment
  ): policyFragment is IdeaPoliciesFragment {
    return (policyFragment as IdeaPoliciesFragment).ideaPolicies !== undefined;
  }

  /**
   * Type predicate to check and type cast a board policies fragment as an occasion policies fragment
   *
   * @param policyFragment The board policies fragment being checked and type cast
   * @returns boolean whether the fragment is an occasion policies fragment
   */
  function isOccasionPolicies(
    policyFragment: BoardPoliciesFragment
  ): policyFragment is OccasionPoliciesFragment {
    return (policyFragment as OccasionPoliciesFragment).occasionPolicies !== undefined;
  }

  /**
   * Type predicate to check and type cast a board policies fragment as a forum policies fragment
   *
   * @param policyFragment The board policies fragment being checked and type cast
   * @returns boolean whether the fragment is a forum policies fragment
   */
  function isForumPolicies(
    policyFragment: BoardPoliciesFragment
  ): policyFragment is ForumPoliciesFragment {
    return (policyFragment as ForumPoliciesFragment).forumPolicies !== undefined;
  }

  /**
   * Checks for showing the Manage Content button based
   * on user permissions.
   */
  function getPolicyChecksForManageContent(): boolean {
    if (isBlogPolicies(board)) {
      return checkPolicy(board.blogPolicies.canManageArticleBlog);
    } else if (isTkbPolicies(board)) {
      return checkPolicy(board.tkbPolicies.canManageArticleTkb);
    } else {
      return false;
    }
  }

  /**
   * Checks for showing the Edit Board Settings button based
   * on user permissions.
   */
  function getPolicyChecksForCanAdminNode(): boolean {
    if (isBlogPolicies(board)) {
      return checkPolicy(board.blogPolicies.canAdminNode);
    } else if (isTkbPolicies(board)) {
      return checkPolicy(board.tkbPolicies.canAdminNode);
    } else if (isForumPolicies(board)) {
      return checkPolicy(board.forumPolicies.canAdminNode);
    } else if (isIdeaPolicies(board)) {
      return checkPolicy(board.ideaPolicies.canAdminNode);
    } else if (isOccasionPolicies(board)) {
      return checkPolicy(board.occasionPolicies.canAdminNode);
    } else {
      return false;
    }
  }

  const adminNodeActions: BoardActionMenuItem[] = [
    {
      name: BoardAction.MANAGE_BOARD_CONTENT,
      policy: (): boolean => getPolicyChecksForManageContent(),
      actionHandler: onManageBoardContentHandler
    },
    {
      name: BoardAction.EDIT_BOARD_SETTINGS,
      policy: (): boolean => getPolicyChecksForCanAdminNode(),
      actionHandler: onEditBoardSettingsHandler
    }
  ];

  /**
   * Function to check if the action item should be displayed
   *
   * @param policy A callback function to determine if action item should be displayed
   * @return boolean whether to display the action item
   * will render if no policy was provided.
   */
  function actionShouldDisplay(policy: () => boolean): boolean {
    if (policy) {
      return policy();
    }
    return true;
  }

  const hasAdminNodeItemsToDisplay: boolean =
    adminNodeActions?.filter(action => actionShouldDisplay(action?.policy)).length > 0;

  /**
   * Renders admin node menu items in Board Action Menu
   *
   * @param action The action to be displayed
   */
  function renderAdminMenuItem(action: BoardActionMenuItem): React.ReactElement {
    const displayAdminMenuItem = actionShouldDisplay(action?.policy);
    const actionHandler = action?.actionHandler;

    if (displayAdminMenuItem) {
      return (
        <Dropdown.Item
          key={action.name}
          onClick={actionHandler}
          className={cx('lia-dropdown-item')}
          data-testid={`BoardActionMenuItem.${action.name}`}
        >
          {formatMessage(`${action.name}.board`)}
        </Dropdown.Item>
      );
    }
    return null;
  }

  /**
   * Renders the edit page link in Group Hub Action Menu
   */
  function renderEditPageLink(): React.ReactElement {
    return pageEditorLink(
      <Dropdown.Item
        key={'editPage'}
        className={cx('lia-dropdown-item')}
        data-testid={'BoardActionMenuItem.EditPage'}
      >
        {formatMessage(`editPage`)}
      </Dropdown.Item>
    );
  }

  return (
    (hasAdminNodeItemsToDisplay || (isPageEditable && canAccessPageBuilder)) && (
      <>
        <Dropdown className={cx('lia-g-ml-10')}>
          <Dropdown.Toggle
            id="lia-board-action-menu"
            as={Button}
            aria-label={formatMessage('toggleButtonLabel')}
            className={cx('lia-g-icon-btn')}
            variant={ButtonVariant.NO_VARIANT}
          >
            <Icon
              icon={Icons.GearIcon}
              size={IconSize.PX_20}
              color={IconColor.GRAY_900}
              testId="BoardActionMenuItem.GearIcon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            aria-labelledby="lia-board-action-menu"
            className={cx('lia-dropdown-menu lia-dropdown-menu-index')}
            popperConfig={dropdownPopperConfig}
            renderOnMount
            data-testid="BoardActionMenuItem.dropdown"
          >
            {hasAdminNodeItemsToDisplay &&
              adminNodeActions.map(action => renderAdminMenuItem(action))}
            {isPageEditable && canAccessPageBuilder && renderEditPageLink()}
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  );
};

export default BoardActionMenu;
